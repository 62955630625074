import React from 'react'
import { Link } from 'react-router-dom'
import abimg from '../../images/about/why_us.jpg'

const ClickHandler = (props) => {
    window.scrollTo(10, 0);
}


const About4 = (props) => {
    return (
        <section className="wpo-about-section-s4 section-padding pb-120">
            <div className="container">
                <div className="wpo-about-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <h2>Our Approach</h2>

                                <div>We deliver high-quality solutions that drive great value for our clients. With a commitment to excellence and innovation, we ensure that every project is executed with efficiency and a deep understanding of our clients’ unique needs.</div>
                                <br/>
                                <div>Here’s what you get when you engage with us:</div>
                                <ul>
                                    <li>Solutions that align with industry standards and best practices, ensuring long-term reliability, scalability, and compliance.</li>
                                    <li>Creative and forward-thinking methods to tackle challenges, focusing on reducing risks and increasing success in every project.</li>
                                    <li>A deployment model that emphasizes the use of lean, highly skilled teams that are agile and effective. This enables us to address issues swiftly and implement solutions efficiently.</li>
                                    <li>We go beyond surface-level fixes by deeply analyzing the root cause of problems. By addressing the core issues, we deliver solutions that provide lasting value and minimize future disruptions.</li>
                                </ul> 
                                <br/>
                                <div>This approach allows us to consistently deliver high-quality outcomes, reduce complexity, and enable insurers to achieve their goals with confidence. At Pollatir, we are dedicated to being a partner you can trust to navigate challenges and unlock opportunities.</div>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-wrap">
                                <div className="wpo-about-img">
                                    <div className="inner-img">
                                        <img src={abimg} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About4;